import { CinemaDto } from '../models/cinema/CinemaDto.types';

export type LocationInterface = {
    latitude: number;
    longitude: number;
};

export function mapLinkMobile(street: string, zip: string, city: string): string {
    return `geo:?q=${street.replace(' ', '+')}, ${city}&z=${zip}`;
}

export function mapLinkDefault(street: string, zip: string, city: string): string {
    return `maps://?q=${street.replace(' ', '+')}, ${city}${zip ? `&z=${zip}` : ''}`;
}

export async function checkUserLocationGranted(): Promise<boolean> {
    const permission = await navigator?.permissions?.query({
        name: 'geolocation'
    });
    return permission?.state === 'granted';
}

export function getLocation(
    success: (location: LocationInterface) => void,
    error?: (error: GeolocationPositionError) => void
): void {
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    navigator.geolocation.getCurrentPosition(
        (position) => {
            success({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
        },
        (positionError) => {
            if (error) {
                error(positionError);
            }
        },
        options
    );
}

export function distanceToCinema(cinema: CinemaDto): string {
    try {
        const distance = cinema?.distance;
        if (!isNaN(distance) && distance > 0) {
            return `${Math.round(distance / 1000)} km`;
        }
    } catch {
        return null;
    }
}

/**
 * Returns the distance rounded depending on how big the number is.
 * @example
 * Between 0 and 1000: E.g. "100 m"
 * Between 1000 and 10000 rounded to 1 decimal: E.g. "1.5 km"
 * Else: E.g. "15 km"
 * @param distanceMeters
 */
export function formatDistance(distanceMeters: number): string {
    if (distanceMeters >= 10000) {
        return (distanceMeters / 1000).toFixed(0) + ' km';
    } else if (distanceMeters >= 1000.0) {
        return (distanceMeters / 1000).toFixed(1) + ' km';
    } else if (distanceMeters >= 100.0) {
        return distanceMeters.toFixed(0) + ' m';
    } else if (distanceMeters > 0.0) {
        return distanceMeters.toFixed(0) + ' m';
    }
}
